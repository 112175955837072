import { Injectable } from '@angular/core';
import { NbToastrService, NbDialogService } from '@nebular/theme';

@Injectable({
  providedIn: 'root',
})
export class AlertService {

  constructor(
    private toastrService: NbToastrService,
  ) {
  }

  // =================================
  // Metodos Genericos
  // ================================

  /**
   * Muestra una notificacion estilo Toastr en la pantalla actual
   * @param  {string} position determina en que parte de la pantalla se representará el Toastr
   * @param  {any} title titulo del Toastr
   * @param  {any} message contenido del Toastr
   * @param  {any} status puede ser (basic, primary, success, info, warning, danger, control)
   * @returns void
   */
  public showToastr({
    position,
    title,
    message,
    status,
  }: {
    position?: any;
    title: string;
    message?: string;
    status: any;
  }): void {
    const realPosition = position ? position : 'top-end';
    const realMessage = message ? message : '';
    const duractionMilisec = 4500;
    this.toastrService.show(`${realMessage}`, `${title}`, {
      position: realPosition,
      status,
      duration: duractionMilisec,
    });
  }

}
