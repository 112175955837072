import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import "rxjs/add/operator/map";
import { map } from "rxjs/operators";
import { PublicModule } from "../public.module";
import { AuthService } from "./auth.service";
import { Router } from "@angular/router";
import { NbToastrService } from "@nebular/theme";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})

export class MainService {
  public user: any;
  public empresa: any;
  public headers: Headers;
  constructor(
    public http: Http,
    private authService: AuthService,
    private router: Router,
    private toastrService: NbToastrService
  ) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Authorization", this.authService.getToken());
    this.user = JSON.parse(localStorage.getItem("usuario")) || {};
  }
  /**
   * API Generico PUT
   * @param {string} api route: api/componente/id
   * @param {any} data propiedades a actualizar
   * @return {Observable<any>} respuesta asincrónica
   */
  /**
   * API Generico GET
   * @param {string} api route: api/componente
   * @return {Observable<any>} respuesta asincrónica
   */
  get({ api }: { api: String }) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Authorization", this.authService.getToken());
    return this.http
      .get(`${environment.baseURL}${api}`, { headers: this.headers })
      .pipe(map((res) => res.json()));
  }
  /*get2(object: String) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Authorization", this.authService.getToken());
    return this.http
      .get(this.baseUrl + "" + object, { headers: this.headers })
      .pipe(map((res) => res.json()));
  }*/
  /**
  * API Generico PUT
  * @param {string} api route: api/componente/id
  * @param {any} data propiedades a actualizar
  * @return {Observable<any>} respuesta asincrónica
  */
  /* put2(object: String, data: any) {
     this.headers = new Headers();
     this.headers.append("Content-Type", "application/json");
     this.headers.append("Authorization", this.authService.getToken());
     return this.http
       .put(this.baseUrl + "" + object, JSON.stringify(data), {
         headers: this.headers,
       })
       .pipe(map((res) => res.json()));
   }*/
  /**
   * API Generico POST
   * @param {string} api route: api/componente
   * @param {any} data objeto a persistir
   * @return {Observable<any>} respuesta asincrónica
   */
  post({ api, data }: { api: String; data: any }) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Authorization", this.authService.getToken());
    return this.http
      .post(`${environment.baseURL}${api}`, JSON.stringify(data), {
        headers: this.headers,
      })
      .pipe(map((res) => res.json()));
  }

  /**
   * API Generico DELETE
   * @param {string} api route: api/componente/id
   * @return {Observable<any>} respuesta asincrónica
   */
  delete({ api }: { api: String }) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Authorization", this.authService.getToken());
    return this.http
      .delete(`${environment.baseURL}${api}`, { headers: this.headers })
      .pipe(map((res) => res.json()));
  }

  /**
   * API Generico PUT
   * @param {string} api route: api/componente/id
   * @param {any} data propiedades a actualizar
   * @return {Observable<any>} respuesta asincrónica
   */
  put({ api, data }: { api: String; data: any }) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Authorization", this.authService.getToken());
    return this.http
      .put(`${environment.baseURL}${api}`, JSON.stringify(data), {
        headers: this.headers,
      })
      .pipe(map((res) => res.json()));
  }

  /**
   * Opens a Nebular Dialog
   * @param  {any} dialog Reference to the template
   * containing the dialog. Must be type ElementRef or TypeReference
   * @returns void
   */
  showToastr({
    position,
    title,
    message,
    status,
  }: {
    position?: any;
    title: string;
    message?: string;
    status: any;
  }): void {
    let realPosition = position ? position : "top-end";
    let realMessage = message ? message : "";
    let duractionMilisec = 4500;
    this.toastrService.show(`${realMessage}`, `${title}`, {
      position: realPosition,
      status,
      duration: duractionMilisec,
    });
  }

  /**
   * Handles navigation to the specified URL
   * @param {string} path -> "/index/home"
   * @return void
   */
  public routerNavigateTo({ path }: { path: string }): void {
    this.router.navigate([path]).then((fulfilled) => {
      if (!fulfilled) {
        this.showToastr({
          title: "Invalid URL",
          status: "basic",
        });
      }
    });
  }

  /**
   * Método POST PROMISE
   * @param {string} object ruta que se ejecutara
   * @param {object} data object con info a enviar al post
   */
  postPromise(object: String, data: any) {
    return new Promise((success, fail) => {
      this.headers = new Headers();
      this.headers.append("Content-Type", "application/json");
      this.headers.append("Authorization", this.authService.getToken());
      this.http
        .post(environment.baseURL + "" + object, JSON.stringify(data), {
          headers: this.headers,
        })
        .pipe(map((res) => res.json()))
        .subscribe(
          (result) => {
            success(result);
          },
          (err) => {
            fail(err);
          }
        );
    });
  }
  sendEmailReport(dataEmail) {
    //let dataEmail = {};
    /*   dataEmail = {
      to: data.email,
      from: data.email,
      nombre: data.nombre,
      subject: data.asunt,
      contenido: data.msjs,
      titulo: "",
      cc: "Prueba",
    }; */
    this.http
      .post(environment.baseURL + "enviar-correo", dataEmail, {
        headers: this.headers,
      })
      .pipe(map((res) => res.json()))
      .subscribe((resp) => {
      });
  }
}
