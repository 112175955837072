import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { uploadDataService } from "../services/uploadData.service";
import * as _ from "lodash";
import * as XLSX from "xlsx";

@Component({
  selector: "ngx-cargue-data",
  templateUrl: "./cargue-data.component.html",
  styleUrls: ["./cargue-data.component.scss"],
})
export class CargueDataComponent implements OnInit {
  @ViewChild("UploadFileInput", { static: false }) uploadFileInput: ElementRef;

  fileUploadForm: FormGroup;
  fileInputLabel: string;
  file: any;
  arrayBuffer: any;
  filelist: Object;
  listCollections: any;
  listFieldbyCollection: any;
  viewFileMatch: boolean;
  itemlist: Object;
  listFields: any;
  profileForm = new FormGroup({});
  collectionForm: FormGroup;
  arraylist: any;
  collectionSelected: string;
  resultadoCargue: any;
  collectionRelational: boolean = false;
  relationalArray = [];
  matchFieldsArray = [];
  checked = [];
  listfieldbyRelation = [];
  relationalFieldTemporal;
  relationalCollectionTemporal;
  listBlockRow: any;

  options = [];
  optionsMap = {};
  optionsChecked = [];
  order: any;
  usuario: any;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private uploadData: uploadDataService
  ) {}

  ngOnInit(): void {
    this.usuario = JSON.parse(localStorage.getItem("usuario"));
    this.fileUploadForm = this.formBuilder.group({
      myfile: [""],
    });
    this.loadCollections();
  }

  async loadCollections() {
    var result = await this.uploadData.getCollections();
    this.listCollections = result.users;
    this.listCollections.sort();
  }

  async loadFields(collectionSelected) {
    this.collectionSelected = collectionSelected;
    var result = await this.uploadData.getFields(collectionSelected);
    this.listFieldbyCollection = result.users;
  }

  async loadFieldsForRelation(collection, field) {
    this.relationalCollectionTemporal = collection;
    var result = await this.uploadData.getFields(collection);
    this.listfieldbyRelation[field] = result.users;
  }

  async matchField(newField, excelFieldName) {
    this.matchFieldsArray.push({ excel: excelFieldName, BDField: newField });
  }

  async keepRelation(field) {
    this.relationalFieldTemporal = field;
  }

  async addRelation(fieldOrigin, id) {
    this.relationalArray.push({
      fieldOrigin: fieldOrigin,
      relationalCollection: this.relationalCollectionTemporal,
      relationalField: this.relationalFieldTemporal,
    });
    //clean
    this.relationalCollectionTemporal = "";
    this.relationalFieldTemporal = "";
  }

  async addfile(event) {
    this.file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = async (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      this.arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      //Most long array
      this.itemlist = await this.checkCompleteObject(this.arraylist);
      this.viewFileMatch = true;
      this.listFields = Object.keys(this.itemlist);
    };
  }

  async Cargar() {
    var data = {
      collection: this.collectionSelected,
      data: this.arraylist,
      isRelational: this.collectionRelational,
      fields: this.relationalArray,
      usuario: this.usuario
    };
    var result = await this.uploadData.addCollection(data);
    this.resultadoCargue = result.users;
  }

  async checkCompleteObject(arr) {
    var maxValue = 0;
    var maxIndex: any;

    for (let i in arr) {
      if (Object.keys(arr[i]).length > maxValue) {
        maxValue = Object.keys(arr[i]).length;
        maxIndex = i;
      }
    }

    return arr[maxIndex];
  }

  initOptionsMap() {
    for (var x = 0; x < this.order.options.length; x++) {
      this.optionsMap[this.options[x]] = true;
    }
  }

  updateCheckedOptions(option, event) {
    this.optionsMap[option] = event.target.checked;
  }

  updateOptions() {
    for (var x in this.optionsMap) {
      if (this.optionsMap[x]) {
        this.optionsChecked.push(x);
      }
    }
    this.options = this.optionsChecked;
    this.optionsChecked = [];
  }
}
