import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import "rxjs/add/operator/map";
import { map } from "rxjs/operators";
import { tokenNotExpired } from "angular2-jwt";
import { PublicModule } from "../public.module";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public authToken: any;
  public user: any;
  public headers: Headers;
  constructor(public http: Http) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    this.user = JSON.parse(localStorage.getItem("usuario")) || {};
  }
  /**
   * API Generico GET componente
   * @param {string} api route: api/componente
   * @return {Observable<any>} respuesta asincrónica
   */
  get2(object: String) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Authorization", this.getToken());
    return this.http
      .get(environment.baseURL + "" + object, { headers: this.headers })
      .pipe(map((res) => res.json()));
  }
  /**
   * API Generico POST
   * @param {string} api route: api/componente
   * @param {any} data objeto a persistir
   * @return {Observable<any>} respuesta asincrónica
   */
  post2(object: String, data: any) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Authorization", this.getToken());
    return this.http
      .post(environment.baseURL + "" + object, JSON.stringify(data), {
        headers: this.headers,
      })
      .pipe(map((res) => res.json()));
  }
  post({ api, data }: { api: String; data: any }) {
    return this.http
      .post(`${environment.baseURL}${api}`, JSON.stringify(data), {
        headers: this.headers,
      })
      .pipe(map((res) => res.json()));
  }
  /**
   * API Generico DELETE
   * @param {string} api route: api/componente/id
   * @return {Observable<any>} respuesta asincrónica
   */
  delete2(object: String) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Authorization", this.getToken());
    return this.http
      .delete(environment.baseURL + "" + object, { headers: this.headers })
      .pipe(map((res) => res.json()));
  }
  public authenticateUser(user) {
    return this.http
      .post(environment.baseURL + "users/authenticate", JSON.stringify(user), {
        headers: this.headers,
      })
      .pipe(map((res) => res.json()));
  }
  public change(email, password) {
    const obj = { email, password };
    return this.http
      .post(environment.baseURL + "users/reset", JSON.stringify(obj), {
        headers: this.headers,
      })
      .pipe(map((res) => res.json()));
  }

  /**
   * API Generico PUT
   * @param {string} api route: api/componente/id
   * @param {any} data propiedades a actualizar
   * @return {Observable<any>} respuesta asincrónica
   */
  put2(object: String, data: any) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Authorization", this.getToken());
    return this.http
      .put(environment.baseURL + "" + object, JSON.stringify(data), {
        headers: this.headers,
      })
      .pipe(map((res) => res.json()));
  }
  public storeUserData(token, user) {
    localStorage.setItem("id_token", token);
    localStorage.setItem("usuario", JSON.stringify(user));
    this.authToken = token;
    this.user = user;
    return true;
  }

  public forgot(email) {
    const obj = { email };
    return this.http
      .post(environment.baseURL + "users/forgot", JSON.stringify(obj), {
        headers: this.headers,
      })
      .pipe(map((res) => res.json()));
  }

  public loadToken() {
    const token = localStorage.getItem("id_token");
    this.authToken = token;
  }
  public logout() {
    this.authToken = null;
    this.user = {};
    localStorage.clear();
  }
  public getToken() {
    this.loadToken();
    return this.authToken;
  }
  public loggedIn() {
    return tokenNotExpired("id_token");
  }
  public getUser() {
    return this.user;
  }
}
